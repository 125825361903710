<template>
   <v-card color="natural lighten-4" >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-row >
            <v-col cols="12" md="5">
                <TextField :title="'หัวข้อคำถาม'" :rules="titleRules" v-bind:value="value.title" @input="update('title',$event)" dense/>
            </v-col>
             <v-col cols="12" md="3">
                <Select dense :title="'รูปแบบการตอบคำถาม'"  v-model="selectedItem" :items="typeItems" item-text="text" item-value="value"/>
            </v-col>
              <v-col cols="12" md="1">
               <div class="text-title-4 natural--text text--darken-4"> บังคับตอบ
               </div>
               <div>
               <v-switch inset v-bind:inputValue="value.required" @change="update('required',$event)"/>
               </div>
            </v-col>
             <v-col cols="12" md="auto">
               <div class="text-title-4 natural--text text--darken-4">
                เครื่องมือ
               </div>
               <div>
<v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
           <v-hover
                    v-slot="{ hover }"
                >
               <v-btn icon @click="$emit('onDelete')" v-on="on" v-bind="attrs"><v-icon :color="hover? 'error': 'natural darken-1'">mdi-delete</v-icon></v-btn>
      </v-hover>
      </template>
        <span>ลบ</span>
</v-tooltip>
<v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
               <v-btn icon @click="$emit('onCopy')" v-on="on" v-bind="attrs"><v-icon>mdi-content-copy</v-icon></v-btn>
      </template>
<span>คัดลอก</span></v-tooltip>
               </div>
            </v-col>
            <v-spacer/>
             <v-col cols="12" md="1">
               <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
           <v-hover
                    v-slot="{ hover }"
                >
                 <v-btn icon  v-on="on" v-bind="attrs" @click="$emit('onUp')"><v-icon :color="hover? 'primary': 'natural darken-1'">mdi-chevron-up-circle</v-icon></v-btn>
      </v-hover>
      </template>
        <span>เลื่อนขึ้น</span>
</v-tooltip>
               <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
           <v-hover
                    v-slot="{ hover }"
                >
                  <v-btn icon v-on="on" v-bind="attrs"  @click="$emit('onDown')"><v-icon  :color="hover? 'primary': 'natural darken-1'">mdi-chevron-down-circle</v-icon></v-btn>
      </v-hover>
      </template>
        <span>เลื่อนลง</span>
</v-tooltip>
             </v-col>
            </v-row>
            <template v-if="value.type">
            <!-- <v-divider/> -->
            <v-row class="pt-0">
                <v-col cols="6">
                     <v-text-field v-if="value.type==='text' || value.type==='number'" :placeholder="'อธิบายคำถามเพิ่มเติม'" v-bind:value="value.placeholder" @input="update('placeholder',$event)" dense/>
                     <template v-if="value.type==='select'">
                          <draggable v-model="value.choices">
                         <template v-for="(item,index) in value.choices">
                          <v-text-field  :key="index" :title="'คำตอบที่ '+ (index+1)" :placeholder="'ระบุคำตอบ'" v-bind:value="value.choices[index].text" @input="updateChoices(index,$event)" dense append-outer-icon="mdi-delete" @click:append-outer="removeChoices(index)" clearable/>
                         </template>
                          </draggable>
                         <template>
                            <v-btn text color="primary" @click="addChoices"> <span>เพิ่มคำตอบ</span></v-btn> หรือ <v-btn text color="primary" @click="addOtherChoices"><span>อื่นๆโปรดระบุ</span></v-btn>
                         </template>
                     </template>
                </v-col>
                  <v-col cols="3">
                  </v-col>
            </v-row>
            </template>
            <template v-if="value.type">
            <v-divider/>
            <v-row class="pt-3">
                 <v-expansion-panels flat>
      <v-expansion-panel
      >
        <v-expansion-panel-header>เงื่อนไขการแสดงผล <v-spacer/></v-expansion-panel-header>
        <v-expansion-panel-content>
        <v-row>
             <v-col cols="4">
                    <Select dense :title="'แสดงผล'" :items="visibleConditionItems" item-text="text" item-value="value" v-model="visibleCondition"/>
                </v-col>
             <v-col cols="4">
                    <Select dense :title="'เลือกคำถาม'" v-model="visibleQuestionItem" :items="visibleQuestion" item-text="title" item-value="name" :disabled="visibleCondition === 'all'"/>
                </v-col>
                 <v-col cols="4">
                     <Select dense :title="'เลือกคำตอบ'" :items="visibleAnswer" v-model="visibleAnsItem" :disabled="visibleCondition === 'all'"/>
                </v-col>
        </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
            </v-row>
            </template>
          </v-card-text>
        </v-form>
        </v-card>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash'

export default {
  props: {
    value: {
      type: Object
    },
    allquestion: {
      type: Array
    }
  },
  components: {
    Select: () => import('@/components/common/Select'),
    TextField: () => import('@/components/common/TextField'),
    draggable: () => import('vuedraggable')
  },
  computed: {
    visibleQuestion () {
      return this.allquestion.filter(function (val) { return val.type === 'select' && val.visible === true })
    },
    visibleAnswer () {
      if (this.visibleQuestionItem) {
        const name = this.visibleQuestionItem
        const ans = this.allquestion.filter(function (val) { return val.name === name })
        if (ans.length > 0) {
          return ans[0].choices
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  data () {
    return {
      valid: false,
      titleRules: [
        (v) => !!v || 'กรุณากรอกข้อมูล'
      ],
      selectedItem: {},
      typeItems: [
        { text: 'ตอบได้ตัวเลือกเดียว', value: 'select' },
        { text: 'ตอบได้หลายตัวเลือก', value: 'multiple' },
        { text: 'คีย์บอร์ดข้อความ', value: 'text' },
        { text: 'คีย์บอร์ดตัวเลข', value: 'number' },
        { text: 'คีย์บอร์ดวันที่', value: 'date' }
      ],
      visibleQuestionItem: null,
      visibleAnsItem: null,
      visibleConditionItems: [
        { text: 'แสดงผลตลอดเวลา', value: 'all' },
        { text: 'แสดงผลเมื่อ', value: 'ifQuestion' }
      ],
      visibleCondition: null,
      deleteDialog: false
    }
  },
  mounted () {
    if (this.value.type === 'select') {
      if (this.value.multiple) {
        this.selectedItem = 'multiple'
      } else {
        this.selectedItem = 'select'
      }
    } else if (this.value.type === 'text') {
      if (this.value.inputType === 'text') {
        this.selectedItem = 'text'
      } else if (this.value.inputType === 'number') {
        this.selectedItem = 'number'
      } else if (this.value.inputType === 'date') {
        this.selectedItem = 'date'
      }
    }
    if (this.value.visibleIf) {
      const property = this.value.visibleIf.match(/\{(.*?)\}/)
      const val = this.value.visibleIf.match(/'([^']+)'/)
      this.visibleQuestionItem = property[1]
      this.visibleAnsItem = val[1]
      this.visibleCondition = 'ifQuestion'
    } else {
      this.visibleCondition = 'all'
    }
    // visibleIf: "{familyDesease}='มีโรค'"
  },
  watch: {
    selectedItem (val) {
      if (val === 'select') {
        this.updateType('type', 'select', 'multiple', false)
      } else if (val === 'text') {
        this.updateType('type', 'text', 'inputType', 'text')
      } else if (val === 'number') {
        this.updateType('type', 'text', 'inputType', 'number')
      } else if (val === 'date') {
        this.updateType('type', 'text', 'inputType', 'date')
      } else if (val === 'multiple') {
        this.updateType('type', 'select', 'multiple', true)
      }
    },
    visibleCondition (val) {
      if (val === 'all') {
        this.$emit('input', tap(cloneDeep(this.value), v => delete v.visibleIf))
      }
    },
    visibleAnsItem (val) {
      if (val) {
        this.$emit('input', { ...this.value, visibleIf: '{' + this.visibleQuestionItem + '}=' + "'" + this.visibleAnsItem + "'" })
      }
    }
  },
  methods: {
    updateType (key1, value1, key2, value2) {
      this.$emit('input', { ...this.value, [key1]: value1, [key2]: value2 })
    },
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    removeChoices (i) {
      if (this.value.choices[i] === 'อื่นๆ') {
        this.$emit('input', tap(cloneDeep(this.value), v => v.choices.splice(i, 1)))
        this.$nextTick(() => {
          this.$emit('input', tap(cloneDeep(this.value), v => delete v.other))
        })
      } else {
        this.$emit('input', tap(cloneDeep(this.value), v => v.choices.splice(i, 1)))
      }
    },
    updateChoices (i, value) {
      this.$emit('input', tap(cloneDeep(this.value), v => set(v.choices, i, { text: value, value: this.value.choices[i].value })))
    },
    addChoices () {
      if (Array.isArray(this.value.choices)) {
        this.$emit('input', tap(cloneDeep(this.value), v => v.choices.push({ text: '', value: '' })))
      } else {
        this.value.choices = []
        this.$emit('input', tap(cloneDeep(this.value), v => v.choices.push({ text: '', value: '' })))
      }
    },
    addOtherChoices () {
      // const other = this.value.choices.filter(function (val) { return val.other === true })
      const other = this.value.choices.filter(function (val) { return val.text === 'อื่นๆ' })
      if (other.length === 0) {
        this.$emit('input', tap(cloneDeep(this.value), v => v.choices.push({ text: 'อื่นๆ', value: 'อื่นๆ' }), v => set(v, 'other', true)))
        this.$nextTick(() => {
          this.$emit('input', { ...this.value, other: true })
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
    margin-top: 5px;
    padding-top: 4px;
}
</style>
